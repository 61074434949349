<script>
import {KEYBOARD} from "../config";
import {mapState} from "vuex";

export default {
  data() {
    return {
      KEYBOARD,
      touchKeyboard: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
    }
  },

  computed: {
    ...mapState([
      'touchkeyboard'
    ]),
    frenchLayout() {
      return {
        _meta: {
          "tab": { key: "\t", text: "Tab", width: 60, classes: "control"},
          "shiftl": { keySet: "shifted", text: "Shift", width: 100, classes: "control"},
          "shiftr": { keySet: "shifted", text: "Shift", width: 100, classes: "control"},
          "caps": { keySet: "capsed", text: "Caps lock", width: 80, classes: "control"},
          "space": { key: " ", text: "Space", width: 180},
          "enter": { key: "\r\n", text: "Enter", width: 80, classes: "control"},
          "backspace": { func: "backspace", classes: "control backspace", width: 65},
          "accept": { func: "accept", text: "Close", classes: "control featured"},
          "next": { func: "next", text: "Next", classes: "control featured"}
        },
        default: [
          `² & é " ' ( - è _ ç à ) = {backspace}`,
          "{tab} a z e r t y u i o p ^ $ *",
          "{caps} q s d f g h j k l m ù {enter}",
          "{shiftl} w x c v b n , ; : ! {shiftr}",
          "{next} {space} , {accept}"
        ],
        shifted: [
          " 1 2 3 4 5 6 7 8 9 0 ° + {backspace}",
          "{tab} A Z E R T Y U I O P ¨ £ µ",
          "{caps} Q S D F G H J K L M % {enter}",
          "{shiftl} W X C V B N ? . / § {shiftr}",
          "{next} {space} , {accept}"
        ],
        capsed: [
          "² 1 2 3 4 5 6 7 8 9 0 ° + {backspace}",
          "{tab} A Z E R T Y U I O P ¨ £ µ",
          "{caps} Q S D F G H J K L M % {enter}",
          "{shiftl} W X C V B N ? . / § {shiftr}",
          "{next} {space} , {accept}"
        ]		
      };
    },
    germanLayout() {
      return {
        _meta: {
          "tab": { key: "\t", text: "Tab", width: 60, classes: "control"},
          "shiftl": { keySet: "shifted", text: "Shift", width: 100, classes: "control"},
          "shiftr": { keySet: "shifted", text: "Shift", width: 100, classes: "control"},
          "caps": { keySet: "capsed", text: "Caps lock", width: 80, classes: "control"},
          "space": { key: " ", text: "Space", width: 180},
          "enter": { key: "\r\n", text: "Enter", width: 80, classes: "control"},
          "backspace": { func: "backspace", classes: "control backspace", width: 65},
          "accept": { func: "accept", text: "Close", classes: "control featured"},
          "next": { func: "next", text: "Next", classes: "control featured"}
        },
        default: [
          "^ 1 2 3 4 5 6 7 8 9 0 ß ´ {backspace}",
          "{tab} q w e r t z u i o p ü + #",
          "{caps} a s d f g h j k l ö ä {enter}",
          "{shiftl} y x c v b n m , . - {shiftr}",
          "{next} {space} , {accept}"
        ],
        shifted: [
          '° ! " § $ % & / ( ) = ? ` {backspace}',
          "{tab} Q W E R T Z U I O P Ü * '",
          "{caps} A S D F G H J K L Ö Ä {enter}",
          "{shiftl} Y X C V B N M ; : _ {shiftr}",
          "{next} {space} , {accept}"
        ],
        capsed: [
          '^ ! " § $ % & / ( ) = ? ´ {backspace}',
          "{tab} Q W E R T Z U I O P Ü * '",
          "{caps} A S D F G H J K L Ö Ä {enter}",
          "{shiftl} Y X C V B N M ; : - {shiftr}",
          "{next} {space} , {accept}"
        ]		
      };
    },
  },

  methods: {
    showTouchKeyboardWithoutEvent() {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.clearIntervalID = setTimeout(() => {
        this.touchKeyboard.visible = true;
      }, 150);
    },
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboard.input = e.target;
      if (!(e.root && e.root.classList.contains('ql-editor'))) {
        this.touchKeyboard.layout = e.target.dataset.layout;
      } else {
        this.touchKeyboard.layout = "normal";
      }

      if (this.touchKeyboard.layout === "normal") {
        if(store.getters['settings/getSettingValue']('geoloc_systemcountry') === "fr") {
          this.touchKeyboard.layout = this.frenchLayout;
        } else if(store.getters['settings/getSettingValue']('geoloc_systemcountry') === "de") {
          this.touchKeyboard.layout = this.germanLayout;
        }
      }

      if (!this.touchKeyboard.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboard.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboard() {
      if (this.touchKeyboard.visible) {
        this.touchKeyboard.visible = false;
      }
    },
    dismissOnScreenKeyboard: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDiv')) {
        if (!document.getElementById('onScreenKeyboardDiv').contains(e.target)) {
          if (!document.activeElement.closest('input, textarea')){
            this.hideTouchKeyboard();
          }
        }
      }
    },
  },

  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboard);
  },

  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboard);
  }
}
</script>
